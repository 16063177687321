import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

@Component
export default class WebsiteSpinner extends BaseMixin {
  @Prop({ type: Boolean }) readonly show!: boolean

  showAnimation = false

  mounted () {
    const interval = setInterval(() => {
      this.showAnimation = this.show

      if (!this.show) clearInterval(interval)
    }, 200)
  }
}
