import { render, staticRenderFns } from "./WebsiteSpinner.vue?vue&type=template&id=b8d645e0"
import script from "./WebsiteSpinner.ts?vue&type=script&lang=ts&external"
export * from "./WebsiteSpinner.ts?vue&type=script&lang=ts&external"
import style0 from "./WebsiteSpinner.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading});
