import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'
import IconEmail from './svg-icons/IconEmail.vue'
import IconInvite from './svg-icons/IconInvite.vue'
import WebsiteRating from './WebsiteRating.vue'

@Component({
  components: {
    IconEmail,
    IconInvite,
    WebsiteRating
  }
})
export default class WebsiteReviewPopup extends BaseMixin {
  @Prop({ type: String }) readonly review!: string
  @Prop({ type: String }) readonly bookTitle!: string
  @Prop({ type: Number }) readonly rating!: number
  @Prop({ type: Boolean }) readonly withButtons!: string
}
