import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from './mixins/base'
import WebsiteTag from './WebsiteTag.vue'

@Component({
  components: {
    WebsiteTag
  }
})
export default class WebsiteMember extends BaseMixin {
  @Prop({ type: String, required: true }) readonly avatar!: string
  @Prop({ type: String, required: true }) readonly name!: string
  @Prop({ type: String }) readonly freeText!: string
  @Prop({ type: String }) readonly authorType!: string
}
